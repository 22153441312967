<template>
    <section class="boutstory" >      <!-- MODIFY -->





        <!-- is_all_fin_for_this_page -->
        <div  v-if='$store.state.system[cdata.system._route_].is_all_fin_for_this_page_with_additional'> 
 <!--class="row d-flex flex-row justify-content-center"-->

 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 

 
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 

<!-- STORY HINT Modal -->
<transition name="bounce" appear>
<div v-if="cdata.show_episode_modal" class="custom-modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-xl" role="document" v-if='$store.state.system[cdata.system._route_].is_all_fin_for_this_page_with_additional'>
      <!--modal-fullscreen-->
      
       <div class="modal-content" style="border:none; background: transparent;">
        
        
        <div class="custom_modal__body px-5" style="position: relative;">
          
        <div @click="go_back_page" class="d-flex align-items-center" style="position: absolute; top:0; right:0; cursor: pointer; padding: 10px; background: #49BDF4; border-radius: 12px; width: 44px; height: 44px;">
            <p style="font-size: 24px; color:white;"><i class="mdi mdi-close"></i></p>
        </div>
          
                <div class=''>
                    <div class="w-100">
<div >
   <div class="">
      <div class="pb-0" >
         <div v-if="cdata.business_logic.view==='card_mode'"   class="card-body" 
            style="padding: 0;">
            <div>
               <!--class='d-flex flex-column justify-content-center'-->
               <!-- https://bootstrap-vue.org/docs/components/carousel indicators -->        
               <b-carousel id="carousel_story" 
                  ref="myCarousel" 
                  :controls='false'
                  background="#ababab" 
                  style="text-shadow: 1px 1px 2px #333;"
                  :interval="0" 
                  fade
                  v-model="cdata.business_logic.curr_card.curr_slide" 
                  :no-wrap='true'
                  :no-touch='false'
                  @sliding-start="onSlideStart"
                  @sliding-end="onSlideEnd"
                  >
                   <!--no-animation--> 
                  <!--:interval="0"  NO AUTO SLIDE!!! DON'T SET OTHER - NEED CHECK CORRECT CHANGE V-MODEL and FLAGS! -->
                  <b-carousel-slide
                     v-for="(page_element, page_index) in cdata.business_logic.all_cards"
                     :key="page_index">
                      
                     <img slot="img" id="mmmmmm" style="border-radius: 12px;"
                          v-bind:src="'/outdataset/'+cdata.business_logic.book_info.serie_code+'/'+cdata.business_logic.book_info.episod_code+'/'+ cdata.business_logic.book_info.chapter_code +'/book/'+'img/'+page_index+'.webp'+'?t='+$root._SYS().add_v()" alt="slide image">

                     
                     <div class='p-3 caption_window'>
                        <!-- blocks ... -->
                        <div
                           v-for="(block_element, block_index) in page_element.blocks"
                           :key="block_index" 
                           >
                           <!-- _quest_ start -->
                           <div  
                              v-if='block_element.type==="_quest_"' 
                              class=''  >
                              <!--show quest contens on here-->
                              <div v-if="block_element.quest_data__for_display">
                                  
                                  <div class="d-flex align-items-center justify-content-center">
                                    <div> 
                                        <div class="d-flex justify-content-center align-items-center">
                                            <p v-if="block_element.quest_data__for_display.quest_options.collect_on_complete" class="mr-2" style="font-size: 20px;">You need to give:</p>
                                            <p v-else class="mr-2" style="font-size: 20px;">You need to collect:</p>
                                            
                                            <div class="d-flex align-items-center">
                                                <div v-for="item in block_element.quest_data__for_display.what_to_give" :key="item.item_id" class="d-flex justify-content-between align-items-center">
                                                    <div class="custom_tooltip" data-side="top">
                                                        <div class="d-flex align-items-center justify-content-center">
                                                            <p class="mr-1" style="font-size: 20px; font-weight: 500;">{{item.amount}}</p>
                                                            <img :src="item.item_img" alt="..." style="width: 25px; max-height: 50px;">
                                                        </div>
                                                        <span class="tooltiptext">{{item.item_name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div v-if="block_element.quest_data__for_display.what_to_get.length > 0" class="d-flex justify-content-between align-items-center">
                                            <p class="mr-2" style="font-size: 20px;">You will get:</p>

                                            <div class="d-flex align-items-center">
                                                <div v-for="item in block_element.quest_data__for_display.what_to_get" :key="item.item_id" class="d-flex align-items-center">
                                                    <div class="custom_tooltip" data-side="bottom">
                                                        <div class="d-flex align-items-center justify-content-center">
                                                            <p class="mr-1" style="font-size: 20px; font-weight: 500;">{{item.amount}}</p>
                                                            <img :src="item.item_img" alt="..." style="width: 25px; max-height: 50px;">
                                                        </div>
                                                        <span class="tooltiptext">{{item.item_name}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     
                                      <div v-if='block_element.quest_data__for_display.already_active == 1
                                       && block_element.quest_data__for_display.already_approved == 1' class="ml-4">
                                         <i style="font-size:24px; color: #55D18E;" class="mdi mdi-check-circle"></i>
                                     </div>
                                     <!--                                     v-if='block_element.quest_data__for_display.already_active == 1
                                       && block_element.quest_data__for_display.already_approved == 1' -->
                                 </div>
                                  
                                  
                                  
                                  
                                  
                                 <div
                                    v-if="block_element.block_mode==='el'"
                                    class='text-center w-100 mt-2' 
                                    @click="do_select_mode"
                                    v-bind:block_mode="'bl'"  
                                    v-bind:pair_qr="page_index" 
                                    v-bind:block_index="block_index" 
                                    >
                                    <!--REDO THIS BLOCK!!-->
                                    <button v-if='block_element.quest_data__for_display.already_active == 1
                                            && block_element.quest_data__for_display.already_approved == 0'   
                                       v-bind:block_mode="'bl'"  
                                       v-bind:pair_qr="page_index" 
                                       v-bind:block_index="block_index" 
                                       class="story_control__buttons accept_btn border-0"
                                       @click="do_quest_complete"
                                       >
                                    Go back to Dashboard
                                    </button> 
                                    
                                    <button v-if='block_element.quest_data__for_display.already_active == 1
                                            && block_element.quest_data__for_display.already_approved == 1'   
                                       v-bind:block_mode="'bl'"  
                                       v-bind:pair_qr="page_index" 
                                       v-bind:block_index="block_index" 
                                       class="story_control__buttons accept_btn border-0"
                                       @click="do_quest_complete"
                                       >
                                    Done
                                    </button>
                                    
                                    
<!--                                    <button v-if='block_element.quest_data__for_display.already_active == 1
                                       && block_element.quest_data__for_display.already_approved == 0'   
                                       v-bind:block_mode="'bl'"  
                                       v-bind:pair_qr="page_index" 
                                       v-bind:block_index="block_index" 
                                       class="story_control__buttons accept_btn border-0"
                                       @click="do_quest_complete"
                                       >
                                    Go back to Dashboard
                                    </button> -->
<!--                                    <font v-if='block_element.quest_data__for_display.already_active == 1
                                       && block_element.quest_data__for_display.already_approved == 1'   
                                       v-bind:block_mode="'bl'"  
                                       v-bind:pair_qr="page_index" 
                                       v-bind:block_index="block_index" 
                                       v-bind:class="'no_selection '+block_element.type"
                                       style="color: #f6e384!important;"
                                       >
                                    done
                                    </font> -->
                                    <button v-else-if='block_element.quest_data__for_display.already_active == 0'     
                                       v-bind:block_mode="'bl'"  
                                       v-bind:pair_qr="page_index" 
                                       v-bind:block_index="block_index" 
                                       class="story_control__buttons accept_btn border-0" 
                                       @click="do_quest_accept(block_element.quest_data)"
                                       :disabled="cdata.business_logic.disable_do_quest_accept_button || cdata.system.buttons.common.is_button_blocked"
                                       >
                                    Accept contract
                                    </button>
                                 </div>
                              </div>
                           </div>
                           <!-- _quest_ end -->
                           <!-- _header_ start -->
                           <div  
                              v-if='block_element.type==="_header_"' 
                              class=''  >
                              <div
                                 v-if="block_element.block_mode==='el'"
                                 class=' text-left w-100' 
                                 @click="do_select_mode"
                                 v-bind:block_mode="'bl'"  
                                 v-bind:pair_qr="page_index" 
                                 v-bind:block_index="block_index" 
                                 >
                                 <font   
                                    v-bind:block_mode="'bl'"  
                                    v-bind:pair_qr="page_index" 
                                    v-bind:block_index="block_index" 
                                    v-bind:class="'no_selection '+block_element.type" 
                                    >
                                 {{block_element['text_'+cdata.system.cnf.el]}} 
                                 </font> 
                              </div>
                           </div>
                           <!-- _header_ end -->
                           <!-- _text_ start -->
                           <div  
                              v-if='block_element.type==="_text_"' 
                              class=''  >
                              <div
                                 v-if="block_element.block_mode==='el'"                                                       
                                 class='pb-1 text-left w-100' 
                                 @click="do_select_mode"
                                 v-bind:block_mode="'bl'"  
                                 v-bind:pair_qr="page_index" 
                                 v-bind:block_index="block_index" 
                                 >
                                 <font  
                                    v-for="(parse_element, parse_index) in block_element.text_el_parse"
                                    :key="parse_index"   
                                    v-bind:block_mode="'bl'"  
                                    v-bind:pair_qr="page_index" 
                                    v-bind:block_index="block_index" 
                                    v-bind:class="'no_selection '+block_element.type"   
                                    >
                                    <!--   https://bootstrap-vue.org/docs/components/tooltip -->
                                    <span v-if="parse_element.includes('|')" 
                                       v-bind:style="'display: inline-block; z-index:11!important; padding-top: 3px!important; padding-bottom: 3px!important; white-space: nowrap; '+get_word_color(parse_element.split('|')[2])"   triggers="focus hover click" v-b-tooltip.hover.top data-html="true" :title="parse_element.split('|')[1]" data-placement="bottom"
                                       v-bind:block_mode="'bl'"  
                                       v-bind:pair_qr="page_index" 
                                       v-bind:block_index="block_index" 
                                       v-bind:class="'no_selection '+block_element.type"   
                                       >{{ parse_element.split('|')[0] }}&nbsp;</span >  
                                    <span v-else  
                                       v-bind:block_mode="'bl'"  
                                       v-bind:pair_qr="page_index" 
                                       v-bind:block_index="block_index" 
                                       v-bind:style="'z-index:11!important; '+get_word_color(parse_element.split('|')[2])"   
                                       v-bind:class="'no_selection '+block_element.type"   
                                       >{{ parse_element }}</span >
                                 </font>
                              </div>
                           </div>
                           <!-- _text_ end -->
                           <!-- _reply_ start -->
                           <div  
                              v-if='block_element.type==="_reply_"' 
                              class=''  >
                              <div
                                 v-if="block_element.block_mode==='el'"                                                      
                                 class='pb-1 text-left w-100' 
                                 @click="do_select_mode"
                                 v-bind:block_mode="'bl'"  
                                 v-bind:pair_qr="page_index" 
                                 v-bind:block_index="block_index" 
                                 >
                                 <font  v-bind:pair_qr="page_index"
                                    v-bind:class="'no_selection '+block_element.type"    
                                    v-bind:style="'z-index:11!important; '+get_word_color(block_element['name_'+cdata.system.cnf['el']].split('|')[2])"   
                                    v-b-tooltip.html.top 
                                    v-b-tooltip.hover 
                                    v-bind:title="block_element['name_'+cdata.system.cnf['el']].split('|')[1]"
                                    >
                                 {{ block_element['name_'+cdata.system.cnf['el']].split('|')[0] }}:   
                                 </font>   
                                 <font  
                                    v-for="(parse_element, parse_index) in block_element.text_el_parse"
                                    :key="parse_index"   
                                    v-bind:block_mode="'bl'"  
                                    v-bind:pair_qr="page_index" 
                                    v-bind:block_index="block_index" 
                                    v-bind:class="'no_selection '+block_element.type"   
                                    >
                                    <!--   https://bootstrap-vue.org/docs/components/tooltip -->
                                    <span v-if="parse_element.includes('|')" 
                                       v-bind:style="'display: inline-block; z-index:11!important; padding-top: 3px!important; padding-bottom: 3px!important; white-space: nowrap; '+get_word_color(parse_element.split('|')[2])"   triggers="focus hover click" v-b-tooltip.hover.top data-html="true" :title="parse_element.split('|')[1]" data-placement="bottom"
                                       v-bind:block_mode="'bl'"  
                                       v-bind:pair_qr="page_index" 
                                       v-bind:block_index="block_index" 
                                       v-bind:class="'no_selection '+block_element.type"   
                                       >{{ parse_element.split('|')[0] }}&nbsp;</span >  
                                    <span v-else  
                                       v-bind:block_mode="'bl'"  
                                       v-bind:pair_qr="page_index" 
                                       v-bind:block_index="block_index" 
                                       v-bind:style="'z-index:11!important; '+get_word_color(parse_element.split('|')[2])"   
                                       v-bind:class="'no_selection '+block_element.type"   
                                       >{{ parse_element }}</span >
                                 </font>
                              </div>
                           </div>
                           <!-- _reply_ end -->
                        </div>
                        <div class="d-flex align-items-center mt-3">
                            
                           <font  v-bind:pair_qr="cdata.business_logic.pair_qr"
                              class="no_selection text-small mr-auto"
                              >
                                <span @click="do_play_audio('','',cdata.business_logic.pair_qr)"  
                                   class="mdi mdi-play-circle-outline" 
                                   style="cursor: pointer; padding: 8px 10px; background: rgb(73, 189, 244); border-radius: 12px; color: white;">
                                </span>   
                           </font>
                           
                           <font v-if="cdata.business_logic.curr_card.curr_slide!==0" 
                              class="no_selection ml-2 story_control__buttons prev_btn" @click="prev_page"   >Previous</font> 
                           <font v-if="cdata.business_logic.curr_card.curr_slide+1!==cdata.business_logic.cnt_all_cards" 
                              class="no_selection ml-2 story_control__buttons next_btn" @click="next_page"  >Next</font> 
<!--                           <font v-if="cdata.business_logic.curr_card.curr_slide+1===cdata.business_logic.cnt_all_cards" 
                              class="no_selection ml-2 story_control__buttons fin_btn" @click="fin_story"  >Okey, I got it</font> -->
                           
                        </div>
                     </div>
                     <!-- autoplay -->
                  </b-carousel-slide>
               </b-carousel>
            </div>
         </div>
         <div class="d-flex flex-column justify-content-center align-items-center">
<!--            <audio hidden ref="myAudio"  class="pb-2 pl-4 pr-4" loop controls autoplay  controlsList="nodownload nofullscreen noremoteplayback noplaybackrate foobar">
               <source v-bind:src="cdata.business_logic.track" 
                  type="audio/mpeg"/>
            </audio>-->
         </div>
      </div>
   </div>
</div>
                    </div>
                </div>
      </div>
        
    </div>
  </div>
    
</div> 
</transition>
 <!--END STORY HINT Modal-->  


        </div>
        <div v-else>Loading...</div>

<!--        <p class="mt-4" >
            Slide #: {{ cdata.business_logic.curr_card.curr_slide }}<br>
            Sliding: {{ cdata.business_logic.curr_card.sliding }}
        </p>-->
            <audio hidden ref="myAudio"  class="pb-2 pl-4 pr-4 audio_background" loop controls autoplay  controlsList="nodownload nofullscreen noremoteplayback noplaybackrate foobar">
               <source v-bind:src="cdata.business_logic.track" 
                  type="audio/mpeg"/>
            </audio>

    </section>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
    import moment from 'moment'
    import 'moment/locale/ru'; //moment.locale('ru');
    import store from '@/store/index.js';
    var cdata = {//Если не указать - не всключиться реактивность 
        /* COPYPASTE MODIFY */
        system: {
            _route_: "book_boutstory", /* MODIFY */
            is_section: {// store/index  -> is_required_sections
                center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
            },
            is_stage: {// store/index  -> is_required_stages
                center: false,
            },
            
            cnf: {},
            buttons: {
                common: {
                    is_button_blocked: true,
                    button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                },
                common_without_symmary: {
                    is_button_blocked: true,
                    button_blocking_exeption_list:
                            {
                                0: "json_version", // json_version
                            }
                },
                button1: {
                    is_button_blocked: true,
                    button_blocking_exeption_list:
                            {
                                0: "json_version", //json_version  
                            }
                },

            },

        },
        errors: {
            is_errors: false,
            actions_error: {},
        },
        business_logic: {
            disable_do_quest_accept_button: false,
            
            tapedTwice: false,
            track: "",
            temp: {},
            cnt_all_cards: 0,
            view: "card_mode", //card_mode list_mode
            mode: "bl", //bl,el 
            is_reversed: false,
            //user_name: "",
            //a_users: {},
            qr: "",
            chapter_qr: "",
            chapter_back: "",
            pair_qr: "",
            is_purchased: false,

            curr_card: {
                curr_slide: -1,
                sliding: false,
                pair_qr: "",
                // card_data: {},   set_curr_card_by_pair_qr
            },
            all_cards: {},

            sets: {},
            sel_set: "",
            sel_set_num: 0,

            book_info: {
                serie_name: "",
                serie_code: "",
                part_name: "",
                part_code: "",
                episod_name: "",
                episod_code: "",
                chapter_name: "",
                chapter_code: "",
                chapter_qr: "",
            },

            //    resered_qr:"XXXX", 
            json_books: {},
        },
        
            window_width: window.innerWidth,
            window_height: window.innerHeight,
            
            show_episode_modal: false,

    }

    export default {
        name: 'boutstory', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },

        created() { },

        mounted() {
            document.getElementById('await_loader_outer').style.display = 'block';
            
            this.$store.dispatch('_system/vuex_set__route_', this.cdata.system._route_);
            this.$root._PAGE().on_mounted__book(this.$store, this.cdata); //обнулить флажки

            setTimeout(() => {
                this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': true, '_route_': this.cdata.system._route_ });
                this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            }, 300);
            
            
            this.$nextTick(() => {
              window.addEventListener('resize', this.onResize);
              this.cdata.show_episode_modal = true;
              this.cdata.business_logic.disable_do_quest_accept_button = false;
              
            });
        },
        beforeMount() {
            window.removeEventListener('resize', this.onResize);
            
                this.cdata = {//Если не указать - не всключиться реактивность 
                    /* COPYPASTE MODIFY */
                    system: {
                        _route_: "book_boutstory", /* MODIFY */
                        is_section: {// store/index  -> is_required_sections
                            center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
                        },
                        is_stage: {// store/index  -> is_required_stages
                            center: false,
                        },

                        cnf: {},
                        buttons: {
                            common: {
                                is_button_blocked: true,
                                button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                            },
                            common_without_symmary: {
                                is_button_blocked: true,
                                button_blocking_exeption_list:
                                        {
                                            0: "json_version", // json_version
                                        }
                            },
                            button1: {
                                is_button_blocked: true,
                                button_blocking_exeption_list:
                                        {
                                            0: "json_version", //json_version  
                                        }
                            },

                        },

                    },
                    errors: {
                        is_errors: false,
                        actions_error: {},
                    },
                    business_logic: {
                        disable_do_quest_accept_button: false,
                        tapedTwice: false,
                        track: "",
                        temp: {},
                        cnt_all_cards: 0,
                        view: "card_mode", //card_mode list_mode
                        mode: "bl", //bl,el 
                        is_reversed: false,
                        //user_name: "",
                        //a_users: {},
                        qr: "",
                        chapter_qr: "",
                        chapter_back: "",
                        pair_qr: "",
                        is_purchased: false,

                        curr_card: {
                            curr_slide: -1,
                            sliding: false,
                            pair_qr: "",
                            // card_data: {},   set_curr_card_by_pair_qr
                        },
                        all_cards: {},

                        sets: {},
                        sel_set: "",
                        sel_set_num: 0,

                        book_info: {
                            serie_name: "",
                            serie_code: "",
                            part_name: "",
                            part_code: "",
                            episod_name: "",
                            episod_code: "",
                            chapter_name: "",
                            chapter_code: "",
                            chapter_qr: "",
                        },

                        //    resered_qr:"XXXX", 
                        json_books: {},
                    },

                        window_width: window.innerWidth,
                        window_height: window.innerHeight,

                        show_episode_modal: false,

                };
                
                this.$store.dispatch('_json/vuex_set__is_loaded_tables__load_status', {'load_status': false, 'table_name': 'json_cards'});
                
                localStorage.removeItem("is_audio_bisy");
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
            
                this.cdata = {//Если не указать - не всключиться реактивность 
                    /* COPYPASTE MODIFY */
                    system: {
                        _route_: "book_boutstory", /* MODIFY */
                        is_section: {// store/index  -> is_required_sections
                            center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
                        },
                        is_stage: {// store/index  -> is_required_stages
                            center: false,
                        },

                        cnf: {},
                        buttons: {
                            common: {
                                is_button_blocked: true,
                                button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                            },
                            common_without_symmary: {
                                is_button_blocked: true,
                                button_blocking_exeption_list:
                                        {
                                            0: "json_version", // json_version
                                        }
                            },
                            button1: {
                                is_button_blocked: true,
                                button_blocking_exeption_list:
                                        {
                                            0: "json_version", //json_version  
                                        }
                            },

                        },

                    },
                    errors: {
                        is_errors: false,
                        actions_error: {},
                    },
                    business_logic: {
                        disable_do_quest_accept_button: false,
                        tapedTwice: false,
                        track: "",
                        temp: {},
                        cnt_all_cards: 0,
                        view: "card_mode", //card_mode list_mode
                        mode: "bl", //bl,el 
                        is_reversed: false,
                        //user_name: "",
                        //a_users: {},
                        qr: "",
                        chapter_qr: "",
                        chapter_back: "",
                        pair_qr: "",
                        is_purchased: false,

                        curr_card: {
                            curr_slide: -1,
                            sliding: false,
                            pair_qr: "",
                            // card_data: {},   set_curr_card_by_pair_qr
                        },
                        all_cards: {},

                        sets: {},
                        sel_set: "",
                        sel_set_num: 0,

                        book_info: {
                            serie_name: "",
                            serie_code: "",
                            part_name: "",
                            part_code: "",
                            episod_name: "",
                            episod_code: "",
                            chapter_name: "",
                            chapter_code: "",
                            chapter_qr: "",
                        },

                        //    resered_qr:"XXXX", 
                        json_books: {},
                    },

                        window_width: window.innerWidth,
                        window_height: window.innerHeight,

                        show_episode_modal: false,

                };
                
                this.$store.dispatch('_json/vuex_set__is_loaded_tables__load_status', {'load_status': false, 'table_name': 'json_cards'});
                
                localStorage.removeItem("is_audio_bisy");
        },
        
        computed: {

            /* COPYPASTE */
            ...mapGetters(['__blocked_tables', '__tables', '__is_errors']),
            _system() {
                let debug = {};
                try {
                    debug['tables'] = this.__tables(this.cdata['system']['_route_']);
                    debug['is_errors'] = this.__is_errors(this.cdata['system']['_route_']);
                    debug['blockings'] = this.__blocked_tables(this.cdata['system']['_route_']);


                } catch (ex) {
                    console.log(ex);
                }
                return debug;
            },
            /* MODIFY */
            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    state['auth'] = this.$store.state['state_tables'].state_auth; /* MODIFY */
                    state['state_books'] = this.$store.state['state_tables'].state_books;/* MODIFY */

                } catch (ex) {
                    console.log(ex);
                }

                return state;
            },

            form() {
                let form = {};// form.classnum


                form['cnt_loaded'] = this.$store.state.cnt_loaded;
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                form['is_all_fin_for_this_page'] = this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page;
                form['is_all_fin_for_this_page_with_additional'] = this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page_with_additional;
                form['classnum'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('classnum') ? this.$store.state.state_tables.state_auth.r_auth['classnum'] : "-1";
                form['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";

                return form;
            }
            /* #ODIFY */
            /* #OPYPASTE */

        },

        watch: {

            /* COPYPASTE на каждое изменение при работе с таблицей два вызова на начало и завершение*/
            "form.cnt_loaded": {
                handler: async function () {

                    console.log("form.cnt_loaded++");

                     //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после выполнения всех стадий, на любое действие с таблицами
                    if (this.$store.state.is_all_stage_fin)
                    {
//                        this.setHalfVolume__audio();
                        document.getElementById('await_loader_outer').style.display = 'none';
                        //console.log("EXECUTE AFTER ALL STAGE LOAD FIN [" + this.cdata.system._route_ + "]");
                    }

                    //ВЫПОЛНИТСЯ РАЗОВО - проверяем готовы ли стадии, для текущей страницы 
                    if (
                             this.$store.state.system[this.cdata.system._route_].is_mounted_execute &&
                            this.$store.state.is_all_stage_fin && 
                            !this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page_executed && 
                            !this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page
                            ) {
                        //проверить завершение всех стадий для страницы (срабатывал раньше времени) 
                        this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page_executed', {'is_all_fin_for_this_page_executed': true, '_route_': this.cdata.system._route_});

                        this.cdata.business_logic.mode = "bl";
                        this.cdata.business_logic.is_reversed = false;

                        var url = new URL(window.location.href);
                        this.cdata.business_logic.qr = url.searchParams.get("qr");
                        this.cdata.business_logic.chapter_qr = url.searchParams.get("qr");
                        this.cdata.business_logic.chapter_back = url.searchParams.get("back");




                        //this.cdata.business_logic.action_qr = this.cdata.business_logic.qr.substring(3, 6);
                        //this.cdata.business_logic.pair_qr = this.cdata.business_logic.qr.substring(6, 10);
                        this.cdata.business_logic.is_purchased = true;
                        this.cdata.business_logic.view = "card_mode";
                        this.cdata.business_logic.cnt_all_cards = 0;


                        //this.$root._AUTH_LOGIC().set_json_books_purchased(this.$store, this.cdata);
                        this.cdata.business_logic['json_catalog'] = JSON.parse(JSON.stringify(this.$store.state.state_tables.state_books.json_catalog));
                        this.$root._AUTH_LOGIC().check_is_chapter_qr_purchased_out(this.$store, this.cdata);


                        this.cdata.business_logic.url_cards_prefix = "/outdataset/" + this.cdata.business_logic.book_info.serie_code + "/" + this.cdata.business_logic.book_info.episod_code + "/" + this.cdata.business_logic.book_info.chapter_code + "/book/" + this.cdata.business_logic.book_info.chapter_code + "_book.json" + '?t=' + this.$root._SYS().add_v();
                        this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json_cards__back(this.$store, this.cdata));









                        this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page', {'is_all_fin_for_this_page': true, '_route_': this.cdata.system._route_});


                        this.$root._SYS().page_v_update(this.$store, this.cdata);

                        console.log("REGISTER ALL TABLE LOAD FIN - FOR PAGE [" + this.cdata.system._route_ + "]");

                        this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
                    }



                    //ВЫПОЛНИТСЯ РАЗОВО - проверяем готовы ли дополниетльные стадии, для текущей страницы - ситуационная подгрузка 
                    if (
                            this.$store.state.system[this.cdata.system._route_].is_mounted_execute &&
                            this.$store.state.is_all_stage_fin &&
                            this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page &&
                            !this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page_with_additional_executed &&
                            !this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page_with_additional
                            ) {


//                        let is_blocked_tbl = false;
//                        for (const [tbl_name, tbl_value] of Object.entries(this.$store.state.blocked_actions[this.cdata.system._route_])) {
//                            //  console.log(tbl_name+"|"+tbl_value); 
//                            for (const [loaded_tbl_name, loaded_tbl_value] of Object.entries(this.$store.state.is_loaded_tables)) {
//
//                                if (loaded_tbl_value.load_status === false)
//                                {
//                                    is_blocked_tbl = true;
//                                }
//                            }
//                        }



//                        if (!is_blocked_tbl) {
                        if (this.$store.state.is_loaded_tables.json_cards.load_status && this.$store.state.is_loaded_tables.json_catalog.load_status) {
//this.setHalfVolume__audio();
                            this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page_with_additional_executed', {'is_all_fin_for_this_page_with_additional_executed': true, '_route_': this.cdata.system._route_});

                            //проставляем завершенные книги... 
                            //this.$root._AUTH_LOGIC().check_is_chapter_fin(this.$store, this.cdata);


                            this.cdata.business_logic.sel_set = "";
                            this.cdata.business_logic.sel_set_num = 0;
                            // await this.$root._AUTH_LOGIC().set_all_cards_for_collection(this.$store, this.cdata);
                            await this.$root._AUTH_LOGIC().set_all_cards_for_book(this.$store, this.cdata);


                            //cdata.business_logic.sets
                            //this.$root._AUTH_LOGIC().set_get_card_sets(this.$store, this.cdata);


                            //выставим нужный слайд curr_slide по pair_qr / на каждый день свой стартовый слайд!!!!
                            //this.$root._AUTH_LOGIC().set_curr_slide_by_pair_qr(this.$store, this.cdata);
                            
                            this.cdata.business_logic.curr_card.curr_slide = 0;
                            console.log("AAAAAAAA_HERE");
                            





                            this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page_with_additional', {'is_all_fin_for_this_page_with_additional': true, '_route_': this.cdata.system._route_});
                            console.log("is_all_fin_for_this_page_with_additional FIN");
                            this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});

//                            this.do_play_audio('','',this.cdata.business_logic.pair_qr)
                           
 
                        
                         //   document.body.getElementById('audio_'+this.cdata.business_logic.curr_card.curr_slide).click();

                        


                        }

                        //Сдесь проверяем пока всё что нужно для кнопок не загрузится!




                    }







                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после загрузки всех стадий для страницы, на любое действие с таблицами
                    if (this.$store.state.system[this.cdata.system._route_].is_all_fin_for_this_page)
                    {
                        //просто постоянно перещитываем счетчики и блокровки кнопок!
                        this.$root._SYS().watch_force_called_on_mounted_execute(this.$store, this.cdata);
                    }



                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },

            "cdata.business_logic.curr_card.curr_slide": {
                handler: function () {
                    this.$root._AUTH_LOGIC().watcher__curr_card__curr_slide_change(this.$store, this.cdata);
                    
                    
                    
                    
                    console.log("LOOK!");

                    if (this.cdata.business_logic.curr_card.curr_slide !== -1)
                    {
                        this.do_play_audio('','',this.cdata.business_logic.pair_qr);
                        
                        let bg_audio_link_pstfx = this.cdata.business_logic.all_cards[this.cdata.business_logic.curr_card.pair_qr].bg_audio_link_pstfx;
                        let way = '/../../outdataset/' + this.cdata.business_logic.book_info.serie_code + '/' + this.cdata.business_logic.book_info.episod_code + '/' + this.cdata.business_logic.book_info.chapter_code + '/book/sound/' + this.cdata.business_logic.book_info.chapter_code + '_m' + bg_audio_link_pstfx + '.mp3';
//                        let way = '/../../outdataset/' + this.cdata.business_logic.book_info.serie_code + '/' + this.cdata.business_logic.book_info.episod_code + '/' + this.cdata.business_logic.book_info.chapter_code + '/book/sound/' + this.cdata.business_logic.book_info.chapter_code + '_m' + bg_audio_link_pstfx + '.mp3' + '?t=' + this.$root._SYS().add_v();
        
                        if (this.cdata.business_logic.track !== way)
                        {

                            this.cdata.business_logic.track = "";
                            this.cdata.business_logic.track = way;

                            //проверка, если текущая песня не равна следующей
                            
                            try {
                                console.log("is_paused?");
                                console.log(this.$refs.myAudio.paused);
                                
//                                if (!this.$refs.myAudio.paused)
                                if (this.$refs.myAudio.paused)
                                {
                                    this.setHalfVolume__audio();
                                    this.$refs.myAudio.load();
                                    this.$refs.myAudio.play();
                                } else
                                {
                                    this.$refs.myAudio.load();
                                    this.$refs.myAudio.pause();
                                }


                            } catch (ex) {
                                console.log(ex);
                            }
                        }
                    }




                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },

        },

        methods: {
            
            setHalfVolume__audio() {
                let audio_tag = document.querySelector(".audio_background");
                audio_tag.volume = 0.05;
                console.log("GOT HERE GVBJHVKJHVKJ")
//                audio_tag.play();
//                this.$refs.myAudio.play();
            },
            
            onResize() {
                this.cdata.window_width = window.innerWidth;
                this.cdata.window_height = window.innerHeight;
            },

            onSlideEnd: function (slide) {
                console.log(slide);
                this.cdata.business_logic.curr_card.sliding = false;
                this.do_play_audio('','',this.cdata.business_logic.pair_qr)
            },

            onSlideStart: function (slide) {
                console.log(slide);
                this.cdata.business_logic.curr_card.sliding = true;
            },

            do_play_audio: async function (block_index, legend_index, page_index) {


                try {



                    let is_audio_bisy = localStorage.getItem("is_audio_bisy");
                    if (is_audio_bisy === "not_bisy" || is_audio_bisy === null)
                    {
                        localStorage.setItem("is_audio_bisy", 'bisy');

                        let link = '';

                        if (block_index !== '')
                        {
                            link = '/outdataset/' + this.cdata.business_logic.book_info.serie_code + '/' + this.cdata.business_logic.book_info.episod_code + '/' + this.cdata.business_logic.book_info.chapter_code + "/book/" + 'sound/blocks/a' + this.cdata.business_logic.pair_qr + '_' + block_index + '.mp3' + '?t=' + this.$root._SYS().add_v();
                        }
                        if (legend_index !== '')
                        {
                            link = '/outdataset/' + this.cdata.business_logic.book_info.serie_code + '/' + this.cdata.business_logic.book_info.episod_code + '/' + this.cdata.business_logic.book_info.chapter_code + "/book/" + 'sound/legends/a' + this.cdata.business_logic.pair_qr + '_' + legend_index + '.mp3' + '?t=' + this.$root._SYS().add_v();
                        }
                        if (page_index !== '')
                        {
                            link = '/outdataset/' + this.cdata.business_logic.book_info.serie_code + '/' + this.cdata.business_logic.book_info.episod_code + '/' + this.cdata.business_logic.book_info.chapter_code + "/book/" + 'sound/blocks/a' + this.cdata.business_logic.pair_qr + '.mp3' + '?t=' + this.$root._SYS().add_v();
                        }

                        console.log("link_NJKNKJNK");
                        var audio = new Audio(link);
                        console.log(link);
                        try {
                            audio.volume = 0.2;
                            audio.play().catch(function (error) {
                                localStorage.setItem("is_audio_bisy", 'not_bisy');
                                console.log("audio.play catch error|" + error);

                            });
                        } catch (ex) {
                            console.log(ex);
                            localStorage.setItem("is_audio_bisy", 'not_bisy');

                        }

                        audio.onended = function () {
                            console.log("is_audio_bisy=false");
                            localStorage.setItem("is_audio_bisy", 'not_bisy');
                        }

                    } else
                    {
                        console.log("is_audio_bisy=true");
                    }

                } catch (ex) {
                    console.log("? fali audio link");
                    localStorage.setItem("is_audio_bisy", 'not_bisy');
                }


            },

            get_word_color: function (word_color) {


                return this.$root._AUTH_LOGIC().get_word_color(this.$store, this.cdata, word_color);


            },

            do_change_view: async function () {
                let view = event.target.getAttribute('view');
                if (!view)
                {
                    view = event.target.parentElement.getAttribute('view');
                }
                this.cdata.business_logic.view = view;

            },

            next_page: function () {
                let is_audio_bisy = localStorage.getItem("is_audio_bisy");
                if (is_audio_bisy === "not_bisy" || is_audio_bisy === null)
                {
                this.$refs.myCarousel.next();
                }
                else
                {
                    console.log("NEXT - Wait while palying finished");
                }
                
            },
            prev_page: function () {
                let is_audio_bisy = localStorage.getItem("is_audio_bisy");
                if (is_audio_bisy === "not_bisy" || is_audio_bisy === null)
                {
                    this.$refs.myCarousel.prev();
                }
                else
                {
                 console.log("PREW - Wait while palying finished");
                }
            },
            fin_story: function () {
                
                let is_audio_bisy = localStorage.getItem("is_audio_bisy");
                if (is_audio_bisy === "not_bisy" || is_audio_bisy === null)
                {
                    //    в зависимости от переданных данных, выполнить редирект с нужной ссылкой
                    this.$router.push('/book/boutpart?part_code='+ this.cdata.business_logic.book_info.part_code +'&serie_code='+ this.cdata.business_logic.book_info.serie_code + '&t=' + this.$root._SYS().add_v());
                    console.log("fin_story");
                    console.log(this.cdata.business_logic.chapter_back);
                }
                else
                {
                    console.log("NEXT - Wait while palying finished");
                }
            },
            
            go_back_page: function () {
                
                let is_audio_bisy = localStorage.getItem("is_audio_bisy");
                if (is_audio_bisy === "not_bisy" || is_audio_bisy === null)
                {
                    this.cdata.show_episode_modal = false;
                    let self = this;
                    setTimeout(function(){
                        self.$router.push('/book/boutpart?part_code='+ self.cdata.business_logic.book_info.part_code +'&serie_code='+ self.cdata.business_logic.book_info.serie_code + '&t=' + self.$root._SYS().add_v());
                    }, 800);
                }
                else
                {
                    console.log("NEXT - Wait while palying finished");
                }
            },
            
            
            
            do_quest_complete: function () {
                let is_audio_bisy = localStorage.getItem("is_audio_bisy");
                if (is_audio_bisy === "not_bisy" || is_audio_bisy === null)
                {
                    //    в зависимости от переданных данных, выполнить редирект с нужной ссылкой
                    this.$router.push('/g1/desksweek/' + '?t=' + this.$root._SYS().add_v());
                    console.log("do_quest_complete");
                    console.log(this.cdata.business_logic.chapter_back);
                }
                else
                {
                    console.log("NEXT - Wait while palying finished");
                }
            },
            
            do_quest_accept: async function (quest_data) {
                //    в зависимости от переданных данных, выполнить редирект с нужной ссылкой
                this.cdata.business_logic.disable_do_quest_accept_button = true;
                
                let quest_data__with_ancestor = {
                    "ancestor_data": {
                        quest_id: this.cdata.business_logic.chapter_qr
                    },
                    "quest_progress": {
                        "status": "new"
                    },
                    ...quest_data
                };
                        
                await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().update_story_quest__multi_ls_and_back(this.$store, this.cdata, quest_data__with_ancestor)).then(
                    this.$snotify.async('Getting backpack prepared', 'Loading', () => new Promise((resolve, reject) => {
                        setTimeout(() => resolve({
                          title: 'Success!!!',
                          body: 'Good Luck, Traveler!',
                          config: {
                            timeout: 3000,
                            showProgressBar: true,
                            pauseOnHover: true,
                            closeOnClick: true
                          }
                        }), 2000);
                    }))
                );
//                BLOCK ACCEPT BTN
                
                this.$router.push('/g1/desksweek/' + '?t=' + this.$root._SYS().add_v());
                console.log(quest_data);
                console.log("do_quest_accept");
                console.log(this.cdata.business_logic.chapter_back);
            },
            
            

            do_select_mode: async function () {


                /*  перевод
                 
                 let pair_qr = event.target.getAttribute('pair_qr');
                 let block_index = event.target.getAttribute('block_index');
                 this.cdata.business_logic.temp.block_index = block_index;
                 this.cdata.business_logic.temp.pair_qr = pair_qr;
                 
                 let mode = event.target.getAttribute('block_mode');
                 if (!mode)
                 {
                 mode = event.target.parentElement.getAttribute('block_mode');
                 }
                 
                 console.log("MODE:" + mode);
                 //-------------------------
                 
                 let self = this; //Дабл клик только на английском double click dbclick
                 if (mode === "bl")
                 {
                 if (!this.cdata.business_logic.tapedTwice) {
                 this.cdata.business_logic.tapedTwice = true;
                 setTimeout(function () {
                 self.cdata.business_logic.tapedTwice = false;
                 }, 300);
                 return false;
                 }
                 event.preventDefault();
                 } else {
                 self.cdata.business_logic.tapedTwice = false;
                 }
                 
                 this.do_select_mode_core(mode);
                 
                 
                 
                 */



            },

            do_select_mode_core: async function (mode) {

                if (mode) {

                    if (!event.path[0].outerHTML.toString().trim().startsWith("<a "))
                    {
                        this.cdata.business_logic.all_cards[this.cdata.business_logic.temp.pair_qr].blocks[this.cdata.business_logic.temp.block_index].block_mode = mode;
                        console.log('CHANGE EN_RU: ' + mode);

                        //вернем все блоки в исходное состояние
                        this.$root._AUTH_LOGIC().set_all_blocks_in_def_lang(this.$store, this.cdata);


                    } else
                    {
                        console.log("DO_TT_LINK");
                    }


                } else
                {
                    console.log("EMPTY_MODE");
                }


            },

            /*
             
             do_select_set: async function () {
             
             let set = event.target.getAttribute('set');
             console.log(set);
             
             let num = event.target.getAttribute('num');
             console.log(num);
             this.cdata.business_logic.sel_set_num = parseInt(num) + 1;
             
             //set==="" ВСЕ    //пересобрать набор нарточек, 2 варианта
             if (set === "")
             {
             
             console.log("here^" + this.cdata.business_logic.pair_qr);
             this.cdata.business_logic.sel_set = "";
             //set nul after choose
             await this.$root._AUTH_LOGIC().set_all_cards_for_collection(this.$store, this.cdata);
             
             if (this.cdata.business_logic.curr_card.curr_slide === 0)
             {
             //принудительно выполняемforse call watch function | same value - watch don't call!!!
             await this.$root._AUTH_LOGIC().watcher__curr_card__curr_slide_change(this.$store, this.cdata);
             
             } else
             {
             this.cdata.business_logic.curr_card.curr_slide = 0; //Сработает триггер!
             }
             
             
             } else
             {
             console.log("2222");
             this.cdata.business_logic.sel_set = set;
             //lost focus
             await this.$root._AUTH_LOGIC().set_all_cards_for_collection(this.$store, this.cdata);
             //выставить нулевую карточку!!!!
             //  console.log("SET=0");
             
             if (this.cdata.business_logic.curr_card.curr_slide === 0)
             {
             //принудительно выполняемforse call watch function | same value - watch don't call!!!
             await this.$root._AUTH_LOGIC().watcher__curr_card__curr_slide_change(this.$store, this.cdata);
             
             } else
             {
             this.cdata.business_logic.curr_card.curr_slide = 0; //Сработает триггер!
             }
             
             
             
             }
             
             
             document.activeElement.blur();
             
             
             },
             
             */



            make_cryptotoken: function (acid, uid, server_action, activ_uid) {

                let cryptotoken = {
                    acid: acid,
                    uid: uid,
                    server_action: server_action,
                    activ_uid: activ_uid,

                };

                let ccryptotoken = window._CRYPT().encrypt_json(cryptotoken);
                return ccryptotoken;
            },

            is_selected_uid: function (uid) {

                try {

                    if (uid === this.$store.state.state_tables.state_auth.r_user.uid)
                    {
                        return true;
                    }
                } catch (ex) {
                    console.log(ex);
                    console.log("ПУСТОЙ АКТИВНЫЙ ПОЛЬЗОВАТЕЛЬ!!!");
                }

                return false;
            },

            move_to: function (new_route) {
                this.$root._PAGE().move_to(this.$store, this.cdata, new_route);
            },

            move_to_acmain: function (new_route) {
                this.$root._PAGE().move_to(this.$store, this.cdata, new_route);
            },

            move_to_acseries: function (new_route) {
                this.$root._PAGE().move_to(this.$store, this.cdata, new_route);
            },
            move_to_accoupon: function (new_route) {
                this.$root._PAGE().move_to(this.$store, this.cdata, new_route);
            },
            move_to_bqr: function (new_route) {
                this.$root._PAGE().move_to(this.$store, this.cdata, new_route);
            },

        }



    }
</script>

<style lang="scss" scoped>

    @media (max-width: 8000px){
        .page-body-wrapper {

        }
        .card {
            width: 52rem !important;
        }
        .card-body {
        }
    }


    @media (max-width: 3960px){

        .page-body-wrapper {

        }
        .card {
            width: 36rem !important;
        }
        .card-body {
        }

    }



    @media (max-width: 1980px){

        .page-body-wrapper {

        }
        .card {
            width: 26rem !important;
        }
        .card-body {
        }

    }





    @media (max-width: 464px){

        .page-body-wrapper {

        }
        .card {
            width: 24rem !important;
        }
        .card-body {
        }

    }





    @media (max-width: 384px){

        .page-body-wrapper {

        }
        .card {
            width: 20rem !important;

        }
        .card-body {
        }
        .card-body p{
            font-size: 85% !important;
        }
        .card-body font{
            font-size: 70% !important;
        }
        .card-body h3{
            font-size: 92% !important;
        }

    }



    @media (max-width: 321px){

        .page-body-wrapper {

        }
        .card {
            width: 18rem !important;
        }
        .card-body {
        }
        .card-body p{
            font-size: 70% !important;
        }
        .card-body font{
            font-size: 60% !important;
        }
        .card-body h3{
            font-size: 80% !important;
        }


    }





    a:not([href]):not([tabindex]) {
        color: inherit;
        text-decoration: none;
    }





    .no_selection {
        user-select: none; /* standard syntax */
        -webkit-user-select: none; /* webkit (safari, chrome) browsers */
        -moz-user-select: none; /* mozilla browsers */
        -khtml-user-select: none; /* webkit (konqueror) browsers */
        -ms-user-select: none; /* IE10+ */
    }


    
    .caption_window {
        border: 3px solid #49BDF4;
        background-color: #FFFFFF; 
        border-radius: 12px;
        /*background-color:rgba(0, 0, 0, 0.6);*/ 
        margin-left:-2.2rem; 
        margin-right:-2.2rem;
        
        color: black;
        line-height: 32px;
/*        line-height: 48px;
        height: 240px;*/
    }
    
    span,
    font{
            color: #000;
            font-size: 20px;
            /*font-size: 28px;*/
    }
    
    .final_chain_outer {
        background: #FFFFFF;
        border-radius: 12px;
        padding: 16px 0px;
        width: 100%;
        
        & img {
            width: 30px;
        }
        
        & p {
            color: #2E3B4B;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
        }
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    

    
/*MODAL*/
.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    outline: 0;
    background: #00000059;
    
    & .modal-content {
        border: none;
        border-radius: 16px;
        /*padding: 24px;*/
    }
    
    & .custom_modal__header {
        display:flex;
        align-items: flex-start;
        justify-content: space-between;
        
        & h5 {
            color: #2E3B4B;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
        }
    }
}

.bounce-enter-active {
  animation: bounce-in .8s;
}
.bounce-leave-active {
  animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
/*END MODAL*/
    
    
    
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
    
    
    
.story_control__buttons {
    border-radius: 12px;
    /*padding: 8px 24px;*/
    padding: 6px 18px;
    
    color: #2E3B4B;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    cursor: pointer;
    
    &.prev_btn {
        background: #DCE0EA;
        box-shadow: 0px 4px 0px #CED2DA;
    }
    &.next_btn {
        background: #49BDF4;
        box-shadow: 0px 4px 0px #44ACDD;
        color: #FFFFFF;
    }
    &.accept_btn {
        background: #55D18E;
        box-shadow: 0px 4px 0px #4FBC81;
        color: #FFFFFF;
    }
}
</style>



<style lang="scss">
    #carousel_story{
        & .carousel-caption {
            /*bottom: 0;*/
            bottom: 2%;
            padding-top: 0;
            padding-bottom: 0;
        }
    }
    
</style>